@import '../variables.scss';

section.introtext {
    .contactlink {
        border-bottom: solid $border-width transparent;
        padding-bottom: .1rem;

        &:hover,
        &:focus {
            border-color: $color-secondary;
            color: $color-secondary;
        }
    }
}
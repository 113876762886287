// Core colors
$color-primary: #5EC8E2;
$color-primary-light: lighten($color-primary, 55%);
$color-primary-darktext: darken($color-primary, 20%);
$color-primary-dark: darken($color-primary, 55%);
$color-primary-darker: darken($color-primary, 60%);
$color-secondary: #757575;
$color-black: #050505;
$color-grey: #999999;
$color-grey-light: #ccc;
$color-grey-dark: #595959;
$color-grey-darker: #1a1a1a;

$body-bg: $color-black;
$body-font: "Roboto", Helvetica, Arial, sans-serif;
$body-font-color: #fff;
$body-font-size: .9rem;
$body-font-size-mobile: 1.2rem;

$heading-font: "Roboto", Helvetica, Arial, sans-serif;
$heading-color: #fff;
$heading-font-size: 1.8rem;

$link-color: $color-primary;
$link-color-light: $color-primary-darktext;

$spacing: 2rem;
$spacing-small: 1rem;

$border-width: .15rem;
$border-radius: .2rem;

$logo-width: 16rem;
$logo-width-compact: 7rem;

// Responsive breakpoints
$halfcol: 480px;
$singlecol: 600px;
$twocol: 1200px;

// Responsive sizing
$responsive-min-screen: $halfcol;
$responsive-max-screen: 1800px;
$responsive-min-value: 13px;
$responsive-max-value: 20px;

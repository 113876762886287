@import '../variables.scss';

section.tech {
    &>div {
        margin: 0.6rem 0 0 0;
    }

    button {
        display: inline-block;
        background-color: $color-secondary;
        padding: $spacing-small / 2;
        margin: 0.5rem 0.4rem;
        border: none;
        box-shadow: none;
        border-radius: $border-radius;
        color: #fff;
        cursor: pointer;

        &:hover,
        &:focus {
            background-color: $color-grey-dark;
        }

        &.active {
            background-color: $color-primary;
            color: $color-black;
        }
    }
}
@import '../variables.scss';

section.nav nav {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    img {
        filter: grayscale(1);
        transition: filter .2s ease-out;
        border-radius: $border-radius
    }

    a {
        display: block;
        position: relative;
        padding: calc(#{$spacing-small} / 2);
        box-sizing: border-box;
        color: $color-black;
        font-size: 1rem;
        animation: fadeIn .5s 1;

        @media (max-width: $singlecol) {
            font-size: 1.2rem;
        }

        #root.theme-light & {
            color: initial;
        }

        span {
            display: inline-block;
            box-sizing: border-box;
            position: absolute;
            background-color: $body-bg;
            left: calc(#{$spacing-small} / 2);
            right: calc(#{$spacing-small} / 2);
            bottom: calc(#{$spacing-small} / 2);
            border-bottom: 0 solid $color-black;
            transition: background .3s,
                color .3s,
                transform .3s,
                width .3s,
                border-width .3s;
            border-bottom-right-radius: $border-radius;
            border-bottom-left-radius: $border-radius;

            #root.theme-light & {
                border-color: #fff;
            }


            em {
                display: block;
                font-style: normal;
                background: $color-primary;
                padding: calc(#{$spacing-small} / 2);
                border-bottom-right-radius: $border-radius;
                border-bottom-left-radius: $border-radius;
                transition: background .3s,
                    color .3s;
            }
        }

        &:hover,
        &:focus {
            img {
                filter: grayscale(0);
            }

            span {
                transform: translateY(95%);
                width: calc(100% - #{$spacing-small});
                z-index: 100;
                border-top-right-radius: 0;
                border-top-left-radius: 0;
                border-bottom-width: $spacing-small;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;

                em {
                    color: #fff;
                    background: $color-secondary;
                }
            }
        }

        // Thumbnail widths
        width: 50%;
        min-height: 100 / 3 * 1vw;

        @media (min-width: 580px) {
            width: 33%;
            min-height: 100 / 4 * 1vw;
        }

        @media (min-width: 800px) {
            width: 100 / 4 * 1%;
            min-height: 100 / 5 * 1vw;
        }

        @media (min-width: 1000px) {
            width: 100 / 5 * 1%;
            min-height: 100 / 6 * 1vw;
        }

        @media (min-width: 1200px) {
            width: 100 / 6 * 1%;
            min-height: 100 / 7 * 1vw;
        }

        @media (min-width: 1400px) {
            width: 100 / 7 * 1%;
            min-height: 100 / 8 * 1vw;
        }

        @media (min-width: 1600px) {
            width: 100 / 8 * 1%;
            min-height: 100 / 9 * 1vw;
        }

        @media (min-width: 1800px) {
            width: 100 / 9 * 1%;
            min-height: 100 / 10 * 1vw;
        }
    }
}
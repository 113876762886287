@import '../variables.scss';

section.content {
    display: flex;
    flex-wrap: wrap;

    article {
        animation: fadeIn .5s 1;
    }

    header {
        flex-grow: 0;
    }

    h2 {
        margin: 0;
        display: inline-block;
        font-weight: normal;
        color: $color-black;
        line-height: 1;
        background-color: $color-primary;
        padding: calc(#{$spacing-small} / 2);
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
        transition: background .1s, color .1s;

        @media (max-width: $halfcol) {
            border-top-left-radius: 0;
        }

        span {
            font-size: .9rem;
            margin-left: $spacing-small;
            margin-right: $spacing-small;

            @media (max-width: $singlecol) {
                font-size: 1.1rem;
            }
        }
    }

    img,
    picture {
        width: 100%;
        height: auto;
    }

    picture {
        display: block;
        border-top-right-radius: $border-radius;
        flex-grow: 0;
        position: relative;
        min-height: 17vw;

        @media (max-width: $halfcol) {
            border-top-right-radius: 0;
        }

        img {
            border: $border-width $color-primary solid;
            width: calc(100% - calc(#{$border-width} * 2));
            transition: border .1s;
            border-top-right-radius: $border-radius;

            @media (max-width: $halfcol) {
                border: 0;
                width: 100%;
            }
        }
    }

    footer {
        background-color: $color-grey-dark;
        padding: calc(#{$spacing-small} / 2);
        opacity: .5;
        transition: opacity .1s;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        flex-grow: 1;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        li {
            display: inline-block;
            text-transform: uppercase;
            font-size: 0.8rem;
            margin-right: calc(#{$spacing-small} / 2);
            line-height: 1.5;

            #root.theme-light & {
                color: #fff;
            }

            @media (max-width: $singlecol) {
                font-size: 1.1rem;
            }
        }
    }

    article {
        padding: calc(#{$spacing} / 2);
        box-sizing: border-box;
        width: 100%;

        @media (max-width: $halfcol) {
            padding-left: 0;
            padding-right: 0;
        }

        @media (min-width: 800px) {
            width: 50%;
            display: flex;
            flex-direction: column;
        }

        @media (min-width: 1600px) {
            width: 100 / 3 * 1%;
        }

        &:hover,
        &:focus {
            footer {
                opacity: 1;
            }
        }
    }

    a {
        display: block;
        color: #fff;

        #root.theme-light & {
            color: #fff;
        }

        &:hover,
        &:focus {
            h2 {
                background-color: $color-secondary;
                color: #fff;
            }

            picture img {
                border-color: $color-secondary;
            }
        }
    }
}
@import '../variables.scss';

#header {
    padding: $spacing;
    display: flex;
    align-items: flex-end;
    border-bottom: $border-width solid $color-primary;
    position: relative;
    position: sticky;
    top: 0;
    z-index: 100;
    transition: padding .2s;
    overflow: hidden;

    @media (max-width: $halfcol) {
        padding-left: $spacing / 2;
        padding-right: $spacing / 2;
    }

    svg {
        display: block;
        width: 100%;
        height: auto;
        max-height: 100%;
        max-width: 100%;
    }

    .logo {
        width: $logo-width;
        margin-right: auto;
        transition: width .2s;
        display: block;
        position: relative;
        z-index: 2;
        border: none;
        background: none;

        @media (max-width: 330px) {
            width: $logo-width * .9;
        }

    }

    .toolbar {
        display: flex;
        position: relative;
        z-index: 100;
        align-items: center;

        svg {
            path {
                fill: $color-primary;

                #root.theme-light & {
                    fill: $color-secondary;
                }
            }
        }
    }

    button {
        background: none;
        border: none;
        width: 3rem;
        height: 1.3rem;
        padding: 0;
        cursor: pointer;

        &:hover {
            svg {
                path {
                    fill: #fff;

                    #root.theme-light & {
                        fill: $color-primary;
                    }
                }
            }
        }
    }

    .link {
        display: block;
        width: 3rem;
        height: 1.3em;

        &.contact {
            height: 1.6rem;
        }

        &:hover,
        &:focus {
            svg {
                path {
                    fill: #fff;

                    #root.theme-light & {
                        fill: $color-primary;
                    }
                }
            }
        }
    }

    .bg {
        position: absolute;
        z-index: 0;

        picture {
            position: relative;
        }
    }
}
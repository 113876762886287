@import '../variables.scss';

#footer {
    padding: $spacing;

    & .top {
        display: block;
        background: $color-black;
        position: fixed;
        bottom: 0;
        right: calc(#{$spacing} * 2);
        font-weight: bold;
        font-size: 2rem;
        text-align: center;
        border-left: $border-width $color-primary solid;
        border-right: $border-width $color-primary solid;
        border-top: $border-width $color-primary solid;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;

        transition: background .1s, color .1s;
        transform: translateY(100%);

        #root.theme-light & {
            background: #fff;
        }

        & span {
            display: block;
            line-height: 1;
            transform: rotate(-90deg);
            padding: 0.25rem 1.05rem 0.55rem 1.2rem;
        }

        &:hover,
        &:focus {
            background: $color-primary;
            color: $color-black;
        }
    }
}

#root.js-header-sticky #footer .top {
    transform: translateY(0);
}
@import '../variables.scss';
@import '../mixins/typography.scss';

// Google Fonts
@import url("https://fonts.googleapis.com/css?family=Roboto&display=fallback");

html {
    @include interpolate(font-size, $responsive-min-screen, $responsive-max-screen, $responsive-min-value, $responsive-max-value);
}

body {
    color: $body-font-color;
    font-family: $body-font;
    font-size: $body-font-size;

    @media (max-width: $singlecol) {
        font-size: $body-font-size-mobile;
    }
}

#root.theme-light {
    color: $color-black;
}

a {
    color: $link-color;
    text-decoration: none;

    #root.theme-light & {
        color: $link-color-light;
    }
}

p {
    line-height: 1.3;
}
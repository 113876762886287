@import '../variables.scss';

body {
    background-color: $body-bg;
}

img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
}

main {
    margin: $spacing 0 0 0;

    @media (min-width: 40rem) {
        display: flex;
        flex-wrap: wrap;
    }

    section {
        box-sizing: border-box;
        padding: 0 $spacing;
        margin: 0 0 $spacing 0;
        width: 100%;

        @media (max-width: $halfcol) {
            padding: 0 $spacing * .5;
            margin: 0 0 $spacing * .5 0;
        }

        &.introtext {
            @media (min-width: 40rem) {
                width: 50%;
            }
        }

        &.tech {
            @media (max-width: $halfcol) {
                padding: ($spacing * .5) - ($spacing-small / 2.2);
            }
            @media (min-width: 40rem) {
                width: 50%;
            }
        }

        &.nav {
            padding-left: calc(#{$spacing} - calc(#{$spacing-small} / 2));
            padding-right: calc(#{$spacing} - calc(#{$spacing-small} / 2));
            @media (max-width: $halfcol) {
                padding-left: ($spacing / 2) - ($spacing-small / 2);
                padding-right: ($spacing / 2) - ($spacing-small / 2);
                }
        
        }

        &.content {
            margin-top: $spacing;
            padding-left: calc(#{$spacing-small});
            padding-right: calc(#{$spacing-small});
            @media (max-width: $halfcol) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

.bg {
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    picture,
    img {
        width: 100%;
        height: 100%;
    }
}
@import '../variables.scss';

.logo path {
    &.color-primary {
        fill: $color-primary;
    }

    &.color-secondary {
        fill: $color-secondary;
    }
}
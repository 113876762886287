@import '../variables.scss';

#root.js-header-sticky #header {
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
    align-items: center;

    .logo {
        width: $logo-width-compact;
    }
}
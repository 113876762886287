@import '../variables.scss';

.bg {
    transition: background .3s;
    background-image: radial-gradient(ellipse at center,
            $color-primary-dark 0%,
            rgba(0, 0, 0, 1) 90%);

    #root.theme-light & {
        background-image: radial-gradient(ellipse at center,
                $color-primary-light 0%,
                rgba(255, 255, 255, 1) 90%);

    }

    &:before {
        transition: background .3s;
        content: '';
        left: 0;
        position: absolute;
        width: 200%;
        height: 100%;
        background:
            repeating-linear-gradient(45deg,
                transparent,
                transparent 1px,
                rgba(0, 0, 0, .2) 1px,
                rgba(255, 255, 255, .05) 1rem);

        #root.theme-light & {
            background:
                repeating-linear-gradient(45deg,
                    transparent,
                    transparent 1px,
                    rgba(210, 210, 210, 0.2) 1px,
                    rgba(255, 255, 255, .05) 1rem);

        }
    }

    &:after {
        transition: background .3s;
        content: '';
        position: absolute;
        width: 200%;
        height: 100%;
        right: 0;
        background:
            repeating-linear-gradient(-45deg, transparent,
                transparent 1px,
                rgba(0, 0, 0, .2) 1px,
                rgba(255, 255, 255, .05) 1rem);

        #root.theme-light & {
            background:
                repeating-linear-gradient(-45deg, transparent,
                    transparent 1px,
                    rgba(210, 210, 210, .2) 1px,
                    rgba(255, 255, 255, .05) 1rem);
        }
    }
}

#header .bg {
    background-image: radial-gradient(ellipse at center,
            $color-primary-darker 0%,
            rgba(0, 0, 0, 1) 50%);

    #root.theme-light & {
        background-image: radial-gradient(ellipse at center,
                $color-primary-light 0%,
                rgba(255, 255, 255, 1) 50%);
    }
}